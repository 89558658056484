import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Контакти | "Смак України: Майстерність Традиційної Кухні"
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Контакти | \"Смак України: Майстерність Традиційної Кухні\""} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/0005-icon.png?v=2023-10-06T15:29:16.081Z"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://uploads.quarkly.io/65201646cd81ee001f7ce217/images/side-view-traditional-russian-ukrainian-red-soup-borscht-with-beef-vegetables-beet-potato-carrot-cabbage-onion-fresh-herbs-spices-clay-bowl-bla_140725-12215%20%281%29.jpg?v=2023-10-06T15:15:23.438Z) center/cover"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="580px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Адреса
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Кулінарна школа "Боско”
								<br />
								Кривчицька Дорога, 17-А, Львів, 79000
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:hello@company.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									contact@croulbar.net
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Телефон
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+38(073)033-37-31{" "}
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Box
							padding="56px 48px"
							margin="0 0 0 auto"
							md-max-width="100%"
							background="rgba(11, 11, 4, 0.52)"
							max-width="480px"
						>
							<Text as="h3" font="--headline3" margin="0 0 20px 0">
								Написати
							</Text>
							<Formspree endpoint="xeqpgrlv">
								<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text margin="0 0 4px 0" font="--base">
												Ім'я
											</Text>
											<Input max-width="400px" width="100%" name="name" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Email
											</Text>
											<Input max-width="400px" width="100%" type="email" name="email" />
										</Box>
									</Box>
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Повідомлення
											</Text>
											<Input width="100%" name="message" as="textarea" rows="4" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Components.QuarklycommunityKitCheckbox>
												<Override slot="Text" color="--light">
													Я ознайомлений з{" "}
													<Link href="/privacy-policy" text-decoration="none" hover-text-decoration="underline" color="--light">
														Правилами та Умовами
													</Link>
													{" "}і даю згоду на обробку моїх данних.
												</Override>
												<Override
													slot="Input"
													min-width="20px"
													min-height="20px"
													text-align="left"
													display="block"
													margin="0px 5px 0px 0px"
												/>
											</Components.QuarklycommunityKitCheckbox>
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column" align-items="flex-start">
											<Button background="--color-secondary" text-decoration-line="initial">
												Відправити
											</Button>
										</Box>
									</Box>
								</Box>
							</Formspree>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65201646cd81ee001f7ce215"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});